import React from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal'
import Play from '../images/play.png'
import {
  bg8,
  modalVideo
} from '../components/styles/Pocketv.stykes'
 
export default class App extends React.Component {
  state = {
    open: false,
  };
 
  onOpenModal = () => {
    this.setState({ open: true });
  };
 
  onCloseModal = () => {
    this.setState({ open: false });
  };
 
  render() {
    const { open } = this.state;
    return (
      <div>
        <div css={[bg8]} role='button' tabIndex='0' onClick={ () => this.onOpenModal() } onKeyDown={this.handleClick} >
          <img src={Play} style={{ width: 60 }}  alt='/' /><br />
          {/* <p>Trailer de 1 min</p> */}
        </div>
        <Modal open={open} onClose={this.onCloseModal} center>
          <iframe css={modalVideo} title='video' src="https://player.vimeo.com/video/470574251" frameborder="0" allow="autoplay; fullscreen" allowfullscreen />
        </Modal>
      </div>
    );
  }
}
 
 