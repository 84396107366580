import React, { Fragment } from 'react'
import Video1 from '../components/Video1'
import Video2 from '../components/Video2'
import Video3 from '../components/Video3'
import Video4 from '../components/Video4'
import Video5 from '../components/Video5'
import Video6 from '../components/Video6'
import Video7 from '../components/Video7'
import Video8 from '../components/Video8'
import Video9 from '../components/Video9'
import Video10 from '../components/Video10'
import Video11 from '../components/Video11'
import Video12 from '../components/Video12'
import Video13 from '../components/Video13'
import Video14 from '../components/Video14'
import Video15 from '../components/Video15'

import {
  inspired,
  container,
  columns, 
  column,
  titleVideo
} from './styles/EstudeAqui.styles'

const InspiredSite = props => (
  <Fragment>
    <div css={inspired}>
    <div css={container}>
       <div css={titleVideo}>Competição Cultural 1984</div>
        <div css={columns}>
          <div css={column}>
            <div style={{ boxShadow: '0px 5px 10px rgba(0,0,0,0.2)', marginBottom: 36 }}>
              <Video10 />
            </div>
          </div>
          <div css={column}>
            <div style={{ boxShadow: '0px 5px 10px rgba(0,0,0,0.2)', marginBottom: 36 }}>
              <Video11 />
            </div>
          </div>
         <div css={column}>
            <div style={{ boxShadow: '0px 5px 10px rgba(0,0,0,0.2)', marginBottom: 36 }}>
              <Video12 />
            </div>
          </div>
        </div>
        <div css={columns}>
          <div css={column}>
            <div style={{ boxShadow: '0px 5px 10px rgba(0,0,0,0.2)', marginBottom: 36 }}>
              <Video13 />
            </div>
          </div>
          <div css={column}>
            <div style={{ boxShadow: '0px 5px 10px rgba(0,0,0,0.2)', marginBottom: 36 }}>
              <Video14 />
            </div>
          </div>
          <div css={column}>
            <div style={{ boxShadow: '0px 5px 10px rgba(0,0,0,0.2)', marginBottom: 36 }}>
              <Video15 />
            </div>
          </div>
          <div css={column} />
          <div css={column} />
        </div>
        <hr />
      </div>
      <div css={container}>
       <div css={titleVideo}>1984 - George Orwell</div>
        <div css={columns}>
          <div css={column}>
            <div style={{ boxShadow: '0px 5px 10px rgba(0,0,0,0.2)', marginBottom: 36 }}>
              <Video5 />
            </div>
          </div>
          <div css={column}>
            <div style={{ boxShadow: '0px 5px 10px rgba(0,0,0,0.2)', marginBottom: 36 }}>
              <Video6 />
            </div>
          </div>
          <div css={column}>
            <div style={{ boxShadow: '0px 5px 10px rgba(0,0,0,0.2)', marginBottom: 36 }}>
              <Video7 />
            </div>
          </div>
        </div>
        <div css={columns}>
          <div css={column}>
            <div style={{ boxShadow: '0px 5px 10px rgba(0,0,0,0.2)', marginBottom: 36 }}>
              <Video8 />
            </div>
          </div>
          <div css={column}>
            <div style={{ boxShadow: '0px 5px 10px rgba(0,0,0,0.2)', marginBottom: 36 }}>
              <Video9 />
            </div>
          </div>
          <div css={column} />
        </div>
        <hr />
      </div>
      <div css={container}>
       <div css={titleVideo}>Segunda Guerra</div>
        <div css={columns}>
          <div css={column}>
            <div style={{ boxShadow: '0px 5px 10px rgba(0,0,0,0.2)', marginBottom: 36 }}>
              <Video1 />
            </div>
          </div>
          <div css={column}>
            <div style={{ boxShadow: '0px 5px 10px rgba(0,0,0,0.2)', marginBottom: 36 }}>
              <Video2 />
            </div>
          </div>
          <div css={column}>
            <div style={{ boxShadow: '0px 5px 10px rgba(0,0,0,0.2)', marginBottom: 36 }}>
              <Video3 />
            </div>
          </div>
        </div>
        <div css={columns}>
          <div css={column}>
            <div style={{ boxShadow: '0px 5px 10px rgba(0,0,0,0.2)', marginBottom: 36 }}>
              <Video4 />
            </div>
          </div>
          <div css={column} />
          <div css={column} />
        </div>
      </div>
    </div>
  </Fragment>
)

export default InspiredSite
