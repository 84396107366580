import React, { Fragment } from 'react'
import { Global } from '@emotion/core'
import { globalStyles } from '../components/layout.styles'
import SEO from "../components/seo"
import NavBar from '../components/NavBar'
import PocketvContent from '../components/PocketvContent'
import Footer from '../components/Footer'
import Pocketv from '../images/pocketv.svg'

import {
  header,
} from '../components/styles/InternalPages.styles'

const StudentsPage = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Olimpíada Pocket Coronavírus" />
    <NavBar location={props.location} />
    <div css={header}>
      <div style={{ marginTop: 160, marginBottom: 32 }}>
        <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection:'column' }}>
          <img src={Pocketv} alt='Pocketv' />
        </div>
      </div>
      <div id='aplicacao' />
      <PocketvContent />
    </div>
    <Footer />
  </Fragment>
)

export default StudentsPage
